a {color:#2196F3;text-decoration:none;}
a:hover {text-decoration:underline;}

.alignc {text-align: center;}
.alignr {text-align: right;}
.pull-left {float: left;}
.pull-right {float: right;}
.contain {overflow: auto;}
.hide, .mobile {display: none;}

.title {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 2px solid #30546b;
}
.title, .subtitle {
  text-transform: uppercase;
  color: #30546b;
  font-size: 1.5em;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 35px;
}

.goog-te-banner-frame {
  display: none !important;
}

.content {
  width: 95%;
  max-width: 960px;
  padding: 60px 0;
  margin: 0 auto;
  position: relative;
}

.answer {
  margin: 20px 0;
  margin-left: 30px;
}

.no-select {
  user-select: none;
}

@media(max-width: 960px) {
  .desktop, .hide-mobile {
    display: none;
  }

  .mobile {
    display: block;
  }
}
