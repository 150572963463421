.faq {
  margin: 5px 0;
  padding: 10px 0;
}

 .num {
  font-size: 16pt;
  display: inline;
  vertical-align: middle;
  margin-right: 10px;
}

.q {
  user-select: none;
  color:#2196F3;
  text-decoration:none;
  font-weight: bold;
  display: inline;
  vertical-align: middle;
  cursor: pointer;
}

.a {
  font-size: 11pt;
  margin-top: 6px;
}
