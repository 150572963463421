.level1, .level2, .level3 {
  line-height: 1.5;
  margin-top: 20px;
}

.level1 {
  font-size: 15px;
  color: #333;
  text-shadow: 0 1px 0 #ddd;
}

.level2 {
  font-size: 13px;
  color: #547a8a;
}

.level3 {
  font-size: 12px;
  color: #555;
}

#mission {
  text-align: center;
  font-style: italic;
}

#partner-logo, #bbb {
  text-align: center;
  margin-top: 20px;
}

#partner-logo img,
#bbb img {
  max-width: 200px;
  width: 80%
}
