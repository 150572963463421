.home {
  height: 100%;
  background-image: url('./images/blue.png');
  background-repeat: repeat repeat;
  background-size: cover;
  background-position: 49% 50%;
}

#motto {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  width: 70%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

#tagline {
  font-size: 1.8em;
  font-size: 4vw;
  font-style: italic;
  margin: 0 auto;
}

.company {
  text-transform: uppercase;
  font-family: 'gotham rounded', Arial, sans-serif !important;
  font-size: 60px;
  font-size: 6.5vw;
  text-shadow: rgb(255, 255, 255) 0px 0px 1px, rgba(0, 0, 0, 0.45098) 0px 1px 2px;
}
