#contain-contact {
  float: left;
  width: 100%;
  max-width: 390px;
  font-size: 17px;
}

#contain-contact h4:first-of-type {
  margin-top: 0;
}

#contain-contact h4 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 21px;
}

#contact-info, #hours-info {
  font-weight: 300;
}

#contact-info td {
  padding: 5px 10px;
}
#contact-info td:nth-child(odd) {
  padding-left: 0;
}

#map {
  float: right;
  padding: 0 10px;
}
#map, #map img {
  width: 100%;
  max-width: 399px;
}

.open, .closed {
  padding: 4px;
}

.open {
  background-color: #90ee90;
}

.closed {
  background-color: #f08080;
}

@media(max-width: 960px) {
  #map {
    float: none;
    margin: 0 auto;
    padding: 0;
  }
  #contain-contact {
    float: none;
    margin: 0 auto;
  }
}
