#all-services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.price, .info {
  display: inline-block;
}
.price {
  float: left;
  margin-left: 10px;
}
.info {
  float: right;
  margin-right: 10px;
}

.hint {
  margin-top: 20px;
  font-size: 11pt;
  font-style: italic;
}

#payment-types {
  text-align: center;
  font-size: 10pt;
  margin-top: -30px;
  margin-bottom: 35px;
}
