.accuracy-wrapper {
  overflow: auto;
  margin: 30px 0 80px 0;
  font-size: 15pt;
}

#chromosome {
  float: left;
  margin: 0 40px 20px 0;
  width: 100%;
  max-width: 280px;
}
