html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {outline: none;}
::-moz-focus-inner {border: 0;}
