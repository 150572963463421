#pages {
  height: 100%;
}

#pages .page {
  width: 100%;
  position: relative;
  background-color: #fff;
}

#pages .page:last-child {
  min-height: 100%;
}

#pages .page:nth-child(even) {
  background-color: #E9F7FF;
}

.content {
  width: 95%;
  max-width: 960px;
  padding: 60px 0;
  margin: 0 auto;
  position: relative;
}
