.service {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 155px;
  width: 193px;
  padding: 16px;
  border-radius: 16px;
  position: relative;
}

.clickable:hover {
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
}

.test {
  text-align: center;
  font-size: 1em;
}

.icon {
  font-size: 6em;
  text-align: center;
  display: block;
  margin: 10px auto;
}

.composite-icon {
  height: 96px;
  margin: 10px 0;
  position: relative;
}

.composite-icon i,
.composite-icon svg {
  position: absolute;
}

.fa-info-circle {
  font-size: 18pt;
  color: rgb(170, 170, 170);
  cursor: pointer;
}

.fa-info-circle:hover {
  color: rgb(150, 150, 150);
}

.new {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #4c4cff;
  color: white;
  padding: 6px;
}
