.popup {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  position: fixed;
  z-index: 20;
  background-color: rgba(200,200,200,.4);
}
.popup .modal {
  width: 360px;
  margin: auto;
  border-radius: 6px;
  background-color: #fff;
  padding: 25px 40px;
  position: relative;
}
.popup .modal .close-popup {
  cursor: pointer;
  margin-top: 10px;
  font-size: .9em;
  color: #00f;
}
.popup .modal #service-name {
  font-size: 1.2em;
  font-weight: bold;
}
.popup .modal #service-data {
  margin-top: 8px;
  overflow: auto;
  max-height: 230px;
}
