#header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  min-height: 32px;
  padding-top: 10px;
  background-color: #E3F2FD;
}

#header .items {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  float: left;
}

#header .items span {
  cursor: pointer;
  margin: 0 20px;
}

#header .items span#current-page {
  color: #673AB7;
  transition: color .7s linear;
}

.mobile #menu.fa.fa-bars {
  float: left;
  margin-right: 10px;
  margin-top: -15px;
  font-size: 19px;
  padding: 15px;
  cursor: pointer;
}

#google-translate-ele {
  float: right;
  margin-right: 20px;
}

@media(max-width: 960px) {
  #header .items {
    float: none;
  }

  #header .items span {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin: 0;
  }
  
  #header .items span:hover {
    background-color: rgba(255,255,255,.3);
  }
}
